import logo from './logo.svg';
import { Provider } from './context.js';
import { ConfigProvider } from "antd";
import { RoutesMain } from './components/RoutesMain';
import { gold } from '@ant-design/colors';

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            // colorPrimary: gold.primary,
            borderRadius: 8,
            background: '#ffedcc'
            // colorBgContainer: '#f6ffed',
          },
          // components: {
          //   Select: {
          //     controlPaddingHorizontal: 'var(--font-size, 16px)',
          //     fontSize: 'var(--font-size, 16px)',
          //   },
          //   Input: {
          //     paddingInline: 'var(--font-size, 16px)',
          //     fontSize: 'var(--font-size, 16px)', // Custom variable
          //   },
          // },
        }}
      >
        <Provider>
          <RoutesMain />
        </Provider>
      </ConfigProvider>
    </div>
  );
}

export default App;
