import React from 'react';
import { Link } from 'react-router-dom';

export const Top = ({ lang = 'en' }) => {
  return (
    <div className="x_top0">
      <div className="w">
        <div className="x_lang0">
          <Link to="/" className={`x_lang ${lang === 'en' ? 'active' : ''} w-inline-block`}>
            <div>English</div>
          </Link>
          <Link to="/ru" className={`x_lang ${lang === 'ru' ? 'active' : ''} w-inline-block`}>
            <div>По-русски</div>
          </Link>
        </div>
      </div>
    </div>
  );
};
