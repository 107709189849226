import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';

import { Card } from 'antd';
const { Meta } = Card;


export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  });
};



export function VideoCard(video) {

  // const { video } = { ...props }

  const { children, view } = { ...video };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();

  const thumbnailUrl = `https://img.youtube.com/vi/${video.youtubeId}/hqdefault.jpg`;



  const YouTubeEmbed = ({ video, view = 'g' }) => {

    const thumbnailUrl = `https://img.youtube.com/vi/${video.youtubeId}/hqdefault.jpg`;

    return (<div className={`x_videos_item0 _tl ${view ? `x_videos_item0--${view}` : ''}`}
    >

      <div
        // className={`x_hover x_hover--sm ${active && videoId === video._id ? 'active' : ''}`}
        className={`x_hover`}
        onClick={() => navigate(`#video=${video._id}${video.id ? `&title=${video.id}` : ''}`)}
      />

      <div className={`x_videos_item ${view ? `x_videos_item--${view}` : ''}`}>
        <div className="x_video_play"
          style={{ backgroundImage: `url(${thumbnailUrl})` }}
        >
          <div className="x_video_play_icon">
            <div className="t_icon">
              <div>play_circle</div>
            </div>
          </div>
        </div>
      </div>


      <div className="_fv _fgap02">
      <div className={`x_videos_item_title ${view ? `x_videos_item_title--${view}` : ''}`}>
        {video['id']}
      </div>
      {view === 'table' && <div className={`x_videos_item_venue ${view ? `x_videos_item_venue--${view}` : ''}`}>
        {video['venue_title']}
        <div className="x_videos_item_date">{video['venue_sub_title']}</div>
      </div>}
        <div className={`x_videos_item_date _t08 ${view ? `x_videos_item_date--${view}` : ''}`}>
        {formatDate(video['date'])}
      </div>
      </div>

    </div>)


  };




  return (
    <>
      <YouTubeEmbed video={video} view={video.view} />
    </>
  )
}