import React, { useEffect, useState, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from './context';
// import { Videos } from './UiKit/Videos';
import { Pop } from './Pop';
import { IcoYoutube } from './components/UiKit/SocialIcos';
import { formatDate } from './components/VideoCard';

// YouTube component that uses the IFrame Player API
const YouTube = ({ video = {} }) => {
  const playerRef = useRef(null);
  const { youtubeId, startTime, endTime } = video;

  useEffect(() => {
    const loadYouTubeIframeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    };

    const onYouTubeIframeAPIReady = () => {
      if (window.YT && playerRef.current) {
        playerRef.current = new window.YT.Player(`youtube-player-${youtubeId}`, {
          videoId: youtubeId,
          playerVars: {
            autoplay: 1,
            rel: 0,
            start: startTime,
            end: endTime
          },
          events: {
            onReady: (event) => {
              event.target.seekTo(startTime);
              event.target.playVideo();
            }
          }
        });
      }
    };

    loadYouTubeIframeAPI();

    if (window.YT) {
      onYouTubeIframeAPIReady();
    } else {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }

    return () => {
      if (playerRef.current && playerRef.current.destroy) {
        playerRef.current.destroy();
      }
    };
  }, [youtubeId, startTime]);

  return (
    <div className="x_pop_video_c">
      <div className="x_pop_video_embed0">
        <div
          id={`youtube-player-${youtubeId}`}
          ref={playerRef}
          className='x_pop_video_embed'
        />
      </div>
    </div>
  );
};


// VideoPop component
export function VideoPop() {
  const context = { ...useContext(Context) };
  const location = useLocation();
  const [videoId, setVideoId] = useState(null);
  const [rel, setRel] = useState(null);
  const [value, setValue] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1));
    const video = params.get('video');
    const related = params.get('rel');
    const relValue = params.get('value');
    const tit = params.get('title');
    setVideoId(video);
    setRel(related);
    setValue(relValue);
    setTitle(tit);
  }, [location]);

  const handleClose = () => {
    setVideoId(null);
    window.history.replaceState(null, '', window.location.pathname + window.location.search);
  };




  return (
    <>
      {Boolean(videoId) && <Pop
        type='video'
        // title={title ? title : context.x.getVideoById(videoId)?.title}
        icon='videocam'
        handleClose={handleClose}
        addContent={<VideoInfo />}
      >
        <YouTube video={context.x.getVideoById(videoId)} />
        {/* <VideoInfo /> */}
      </Pop>}
    </>
  );
}


const VideoInfo = () => {

  const context = { ...useContext(Context) };
  const location = useLocation();
  const [videoId, setVideoId] = useState(null);
  const [rel, setRel] = useState(null);
  const [value, setValue] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.slice(1));
    const video = params.get('video');
    const related = params.get('rel');
    const relValue = params.get('value');
    const tit = params.get('title');
    setVideoId(video);
    setRel(related);
    setValue(relValue);
    setTitle(tit);
  }, [location]);


  const videoData = context.x.getVideoById(videoId) || {};

  return <div className="m_b_extra0">
    <div className="x_pop_video_i0">
      <div className="x_pop_video_i">
        <div className="x_pop_video_i_title">{title ? title : videoData?.title}</div>
        <div className="x_pop_v ideo_i_sub">{videoData?.venue_title}, {videoData?.venue_sub_title}</div>
        <div className="x_pop_video_i_date">{formatDate(videoData?.date)}</div>
      </div>
      <a href={`https://www.youtube.com/watch?v=${videoData?.youtubeId}`} target="_blank" className="x_f_social0 w-inline-block">
        <div className="x_f_social_ico">
          <div className="x_f_social_ico w-embed">
            <IcoYoutube />
          </div>
        </div>
        <div className="x_f_social_name">
          <div>Watch on Youtube</div>
        </div>
      </a>
    </div>
  </div>
}
