import React, { useEffect, useState } from 'react';

export const Videos = () => {
  const [videoCategories, setVideoCategories] = useState('');
  const [venues, setVenues] = useState('');
  const [videos, setVideos] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await fetch('/data/videoCategories.json');
        const venuesResponse = await fetch('/data/venues.json');
        const videosResponse = await fetch('/data/videos.json');

        if (!categoriesResponse.ok || !venuesResponse.ok || !videosResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        const categoriesData = await categoriesResponse.json();
        const venuesData = await venuesResponse.json();
        const videosData = await videosResponse.json();

        console.log('Categories Data:', categoriesData);
        console.log('Venues Data:', venuesData);
        console.log('Videos Data:', videosData);

        setVideoCategories(JSON.stringify(categoriesData, null, 2));
        setVenues(JSON.stringify(venuesData, null, 2));
        setVideos(JSON.stringify(videosData, null, 2));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>Videos Data</h1>
      <h2>Video Categories</h2>
      <textarea
        rows="10"
        cols="50"
        value={videoCategories}
        readOnly
      />
      <h2>Venues</h2>
      <textarea
        rows="10"
        cols="50"
        value={venues}
        readOnly
      />
      <h2>Videos</h2>
      <textarea
        rows="10"
        cols="50"
        value={videos}
        readOnly
      />
    </div>
  );
};



