import React from 'react';

const SyncDataButton = () => {
  const handleSyncData = async () => {
    const response = await fetch('/.netlify/functions/syncData', {
      method: 'POST',
    });
    const data = await response.json();
    if (response.ok) {
      alert(data.message);
    } else {
      alert('Error syncing data: ' + data.error);
    }
  };

  return (
    <button onClick={handleSyncData}>
      Sync Data
    </button>
  );
};

export default SyncDataButton;
